import React from "react";
import {Route, Redirect, Switch} from "react-router-dom";
import {ConnectedRouter} from "react-router-redux";
import {connect} from "react-redux";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import Auth0 from "./helpers/auth0";

const RestrictedRoute = ({component: Component, isLoggedIn, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/signin",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);
const PublicRoutes = ({history, isLoggedIn}) => {
    return (
        <ConnectedRouter history={history}>
            <div>
                <Switch>
                    <Route
                        exact
                        path={"/"}
                        component={asyncComponent(() => import("./protectedMediaDashboardApp/containers/SignIn/signin"))}
                    />
                    <Route
                        exact
                        path={"/404"}
                        component={asyncComponent(() => import("./containers/Page/404"))}
                    />
                    <Route
                        exact
                        path={"/500"}
                        component={asyncComponent(() => import("./containers/Page/500"))}
                    />
                    <Route
                        exact
                        path={"/signin"}
                        component={asyncComponent(() => import("./protectedMediaDashboardApp/containers/SignIn/signin"))}
                    />
                    <Route
                        exact
                        path={"/signup"}
                        component={asyncComponent(() => import("./containers/Page/signup"))}
                    />
                    <Route
                        exact
                        path={"/forgotpassword"}
                        component={asyncComponent(() =>
                            import("./containers/Page/forgotPassword")
                        )}
                    />
                    <Route
                        exact
                        path={"/resetpassword"}
                        component={asyncComponent(() =>
                            import("./containers/Page/resetPassword")
                        )}
                    />

                    <Route
                        path="/auth0loginCallback"
                        render={props => {
                            Auth0.handleAuthentication(props);
                        }}
                    />
                    <Route
                        path="/docs"
                        component={asyncComponent(() => import("./protectedMediaDashboardApp/containers/SignIn/signin"))}
                    />
                    <RestrictedRoute
                        path="/dashboard"
                        component={App}
                        isLoggedIn={isLoggedIn}
                    />
                    <Route path="*" component={asyncComponent(() => import("./containers/Page/404"))}/>
                </Switch>
            </div>
        </ConnectedRouter>
    );
};

export default connect(state => ({
    isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
