import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import userActions from '../users/actions';
import ReportHelper from '../../../helpers/reportHelper';
import message from '../../../components/uielements/message';
import MessageContent from "../../../containers/Feedback/Message/message.style";
import React from "react";
import ReactGA from "react-ga4";
import {delay} from "redux-saga";

import {
    downloadFileFromUrl,
    ReportPropertiesQueryParameters,
    getInitialMetrics,
    addMandatoryMetricsToSelected
} from "../../../helpers/utility";
import theme from "../../../settings/themes/protectedmediatheme";
import moment from "moment";
import UserHelper from "../../../helpers/userHelper";
import {
    DIMENSIONS,
    presetTimeRanges,
} from '../../containers/dashboard/config'
import clone from 'clone';
import {gaTrackersNames} from "../../../settings";

export const getReportProperties = (state) => state.ReportsFetch.reportProperties;
export const getPidsFromUser = (state) => state.UsersFetch.pids;
export const getIsUserMaster = (state) => state.UsersFetch.isMaster;
export const getCurrentSolutions = (state) => state.ReportsFetch.solutions;
export const getIsInternalUser = (state) => state.UsersFetch.isInternalUser;

const DUMMY_SMART_AGENT_FILTERS = [
    {
        "field": "total",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    },
    {
        "field": "ivt_total_ivt",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    },
    {
        "field": "vi_ad_visibility_1",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    },
    {
        "field": "vi_tos_1",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    },
    {
        "field": "bp_total",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    },
    {
        "field": "tq_insights_available",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    },
    {
        "field": "eng_total",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    },
    {
        "field": "trusted_impressions",
        "isPercentage": false,
        "relationOperator": ">=",
        "value": 0
    }
]

function identifyPidForThirdPartyTools(pidData) {
    ReactGA.set({
        dimension2: `${pidData.pid} - ${pidData.name}`
    }, gaTrackersNames);
}

export function* reportFetchRequest() {
    yield takeEvery(actions.FETCH_REPORT, function* () {
        const reportsProperties = yield select(getReportProperties);

        const modifiedReportProperties = { ...reportsProperties };
        modifiedReportProperties.timeDimensions = [];

        const isUserMaster = yield select(getIsUserMaster);
        const currentSolutions = yield select(getCurrentSolutions);
        let validReportProperties = clone(reportsProperties);
        validReportProperties.dimensions = validReportProperties.dimensions
            .filter(dimension => {
                const currentDimensionProperties = DIMENSIONS.find(dimensionProperties => dimensionProperties.key === dimension)
                return currentDimensionProperties && (isUserMaster || !currentDimensionProperties.forMasterUsers)
            });
        validReportProperties.selectedMetrics = validReportProperties.selectedMetrics.length ?  addMandatoryMetricsToSelected(validReportProperties.selectedMetrics || [], currentSolutions)  : getInitialMetrics(isUserMaster, currentSolutions);

        try {
            const reportId = ReportPropertiesQueryParameters.getQueryParametersFromReportProperties(validReportProperties)
            const chartsReportId = ReportPropertiesQueryParameters.getQueryParametersFromReportProperties(modifiedReportProperties)
            yield all([
                put(actions.reportInsightsFetch()),
                put(actions.highRatesMessagesFetch()),
                put(actions.smartAgentDataFetch()),
            ])

            yield fork(fetchGraphsDataSaga, chartsReportId)

            const data = yield call(ReportHelper.fetchReportData, reportId)

            if (data) {
                window.location.hash = reportId
                yield all([
                    put(actions.reportFetchSuccess(data)),
                    put(userActions.notificationsFetch()),
                ])
            } else {
                yield put(actions.reportFetchError())
            }

            identifyPidForThirdPartyTools(reportsProperties.pid)

        } catch (error) {
            yield put(actions.reportFetchError());
        }
    });
}

function* fetchGraphsDataSaga(chartsReportId) {
    try {
        const graphs = yield call(ReportHelper.fetchChartsData, chartsReportId);
        if (graphs) {
            yield put(actions.graphsFetchSuccess(graphs));
        }
    } catch (error) {
        yield put(actions.graphsFetchError(error));
    }
}

export function* reportInsightsFetchRequest() {
    yield takeEvery(actions.FETCH_REPORT_INSIGHTS, function* () {
        const reportsProperties = yield select(getReportProperties);
        try {
            const reportId = ReportPropertiesQueryParameters.getQueryParametersFromReportProperties(reportsProperties);
            const insights = yield call(ReportHelper.fetchInsightsData, reportId);

            if (insights) {
                yield put(actions.reportInsightsFetchSuccess(insights));
            } else {
                yield put(actions.reportInsightsFetchError());
            }
        } catch (error) {
            yield put(actions.reportInsightsFetchError());
        }
    });
}

export function* highRatesMessagesRequest() {
    yield takeEvery(actions.FETCH_HIGH_RATES_MESSAGES, function* () {
        const reportProperties = yield select(getReportProperties);
        try {
            const reportId = ReportPropertiesQueryParameters.getQueryParametersFromReportProperties(reportProperties);
            const highRatesMessages = yield call(ReportHelper.fetchHighRatesMessagesData, reportId);
            yield put(actions.highRatesMessagesSuccess(highRatesMessages));
        } catch (error) {
            yield put(actions.highRatesMessagesError());
        }
    });
}

export function* savedReportFetchRequest() {
    yield takeEvery(actions.FETCH_SAVED_REPORT, function* (payload) {
        const {savedReportProperties} = payload;
        const {reportProperties} = savedReportProperties;
        const userPids = yield select(getPidsFromUser);

        const pidProperties = userPids[reportProperties.pid.pid];
        const timeRange = presetTimeRanges(moment.utc())[savedReportProperties.relativeTimeRange];
        if (pidProperties) {
            yield put(actions.reportFetch({
                pid: {
                    ...reportProperties.pid,
                    ...userPids[reportProperties.pid.pid]
                },
                timeRange: {
                    from: timeRange[0],
                    to: timeRange[1]
                },
                timeZone: reportProperties.timeZone,
                timeDimensions: reportProperties.timeDimensions,
                dimensions: reportProperties.pivot || reportProperties.dimensions,
                selectedMetrics: reportProperties.metrics,
                conditions: reportProperties.conditions,
                filters: reportProperties.filters
            }))
        }
    });
}


export function* reportExportRequest() {
    yield takeEvery(actions.EXPORT_REPORT, function* (payload) {
        const {reportProperties, currentSolutions, compression} = payload.payload;
        const timeDimensions = reportProperties.timeDimensions
        let selectedMetrics = addMandatoryMetricsToSelected(reportProperties.selectedMetrics || [], currentSolutions);
        if (!selectedMetrics || selectedMetrics.length === 0) {
            const isUserMaster = yield select(getIsUserMaster);
            const currentSolutions = yield select(getCurrentSolutions);
            selectedMetrics = getInitialMetrics(isUserMaster, currentSolutions);
        }
        message.info(<MessageContent>Your file will be ready in a few minutes, keep this tab open and we'll let you know
            when it's ready.
            Thanks for your patience!</MessageContent>);
        try {
            let response = yield call(ReportHelper.startReportExportJob, {...reportProperties, selectedMetrics: selectedMetrics}, timeDimensions,
                currentSolutions, compression);
            if (response.signed_url && response.signed_url.startsWith('http')) {
                downloadFileFromUrl(response.signed_url);
                yield put(
                    actions.reportExportSuccess(response.signed_url)
                );
            } else if (['started', 'pending'].indexOf(response.status) >= 0 || response.hasOwnProperty('error')) {
                yield put(actions.reportExportUpdateProgress(response));
                yield call(delay, 3000);
            } else {
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.reportExportError());
            }
        } catch (error) {
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            yield put(actions.reportExportError());
        }
    });
}

const ERROR_MESSAGE_ARGUMENTS = <MessageContent>Oh, no! Looks like something went wrong...
    Please try again later</MessageContent>;

export function* openSupportTicketRequest() {
    yield takeEvery(actions.OPEN_SUPPORT_TICKET, function* (payload) {
            const {issueDetails, reportProperties, contactInfo, onSubmit} = payload.payload;
            try {
                let issueId = yield call(ReportHelper.openSupportTicket, issueDetails, reportProperties, contactInfo);
                if (issueId && issueId.indexOf('Failed') < 0 && issueId.indexOf('<h') < 0 && issueId.indexOf('Maximum') < 0) {
                    message.success(<MessageContent>
                        <b>Your Ticket Was Submitted!</b><br/>
                        Your customer success manager will get back to you as soon as possible.<br/>
                        <p style={{color: theme.palette.secondary[2]}}>Reference Number: {issueId}</p>
                    </MessageContent>, 30);
                    onSubmit();
                    yield put(actions.openSupportTicketDone());
                } else {
                    message.error(issueId === 'Maximum tickets opened for user' ?
                        <MessageContent>Oh, no! Looks like You created too many tickets today...
                            Please try again later or send an email to your account manager</MessageContent> :
                        ERROR_MESSAGE_ARGUMENTS, 20);
                    onSubmit();
                    yield put(actions.openSupportTicketDone());
                }
            } catch {
                message.error(ERROR_MESSAGE_ARGUMENTS, 20);
                onSubmit();
                yield put(actions.openSupportTicketDone());
            }
        }
    );
}

export function* savedReportUpdateRequest() {
    yield takeEvery(actions.UPDATE_SAVED_REPORT, function* (
        payload) {
        try {
            const response = yield call(
                UserHelper.updateSavedReport, payload.savedReportProperties);
            if (response && !response.error) {
                if (response.reportType === 'traffic_alert') {
                    yield all([
                        put(actions.savedReportUpdateSuccess()),
                        put(userActions.reportsFetch(payload.savedReportProperties.doNotFetchReportAfterUpdate ? null : response.id))
                    ]);
                } else {
                    yield all([
                        put(actions.savedReportUpdateSuccess()),
                        put(userActions.usageNotificationsFetch()),
                    ]);
                }
            } else {
                message.config({maxCount: 1});
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.savedReportUpdateError());
            }
        } catch (error) {
            message.config({maxCount: 1});
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            yield put(actions.savedReportUpdateError());
        }
    });
}

export function* savedReportRemoveRequest() {
    yield takeEvery(actions.REMOVE_SAVED_REPORT, function* (
        payload) {
        try {
            const response = yield call(
                UserHelper.removeSavedReport, payload.savedReportProperties);
            if (response && !response.error) {
                if (payload.savedReportProperties.usageNotificationData && payload.savedReportProperties.usageNotificationData.threshold) {
                    yield all([
                        put(actions.removeReportSuccess()),
                        put(userActions.usageNotificationsFetch())
                    ]);
                } else {
                    yield all([
                        put(actions.removeReportSuccess()),
                        put(actions.savedReportClear()),
                        put(userActions.reportsFetch())
                    ]);
                }
            } else {
                message.config({maxCount: 1});
                message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                    contact your account manager</MessageContent>, 20);
                yield put(actions.savedReportUpdateError());
            }
        } catch (error) {
            message.config({maxCount: 1});
            message.error(<MessageContent>There seems to be an issue. Please try again in a few minutes or
                contact your account manager</MessageContent>, 20);
            yield put(actions.savedReportUpdateError());
        }
    });
}

export function* smartAgentFetchRequest() {
    yield takeEvery(actions.FETCH_SMART_AGENT_DATA, function* (payload) {
        const isInternalUser = yield select(getIsInternalUser);
        if(isInternalUser) {
            const reportProperties = yield select(getReportProperties);
            let manipulatedReportProperties = clone(reportProperties)
            manipulatedReportProperties.filteredSolutions = ['ivt']
            manipulatedReportProperties.filters = DUMMY_SMART_AGENT_FILTERS
            try {
                const reportId = ReportPropertiesQueryParameters.getQueryParametersFromReportProperties(manipulatedReportProperties);
                const smartAgentData = yield call(ReportHelper.fetchSmartAgentData, reportId);
                yield put(actions.smartAgentDataFetchSuccess(smartAgentData));
            } catch (error) {
                yield put(actions.smartAgentDataFetchError());
            }
        }
    });
}


export default function* rootSaga() {
    yield all([
        fork(reportFetchRequest),
        fork(reportInsightsFetchRequest),
        fork(reportExportRequest),
        fork(openSupportTicketRequest),
        fork(savedReportFetchRequest),
        fork(savedReportUpdateRequest),
        fork(savedReportRemoveRequest),
        fork(highRatesMessagesRequest),
        fork(smartAgentFetchRequest)
    ]);
}