import settings, {jwtConfig} from '../settings';
import message from "../components/feedback/message";
import Button from '../components/uielements/button';
import React from "react";

function isDataForm(data) {
    return data && data.toString() === "[object FormData]"
}

function parseResponse(response) {
    return response.clone().json()
        .then(response => response)
        .catch(() => response.clone().text());
}

const customHeader = (isLogin, isForm) => {
    let header = {Authorization: 'JWT ' + localStorage.getItem('id_token') || undefined};
    if (!isForm) {
        const contentType = isLogin ? 'application/x-www-form-urlencoded; charset=UTF-8' : 'application/json';
        return {
            ...header,
            'Content-Type': contentType,
            Accept: contentType
        }
    }
    return header;
};

const customBody = (url, data) => {
    if (url === 'login/') {
        return Object.entries(data).map(e => e.join('=')).join('&')
    }
    return isDataForm(data) ? data : JSON.stringify(data)
};

const base = (method, url, data) => {
    const queryString = (method === 'get') && data
        ? '?' + new URLSearchParams(data).toString()
        : '';
    return fetch(`${settings.apiUrl}${jwtConfig.fetchUrl}${url}${queryString}`, {
        method,
        // headers: customHeader(),
        // body: JSON.stringify(data),
        //Server V1 compatibility
        headers: customHeader(url === 'login/', isDataForm(data)),
        body: (method !== 'get') ? customBody(url, data) : undefined,
    })
        .then(parseResponse)
        .then(res => {
            if (res === "Authentication failed.") {
                // Save the existing dashboard query url to redirect to after re-login
                const queryUrlPart = window.location.hash;
                message.config({maxCount: 1});
                message.error(<span>The session is expired. <Button onClick={() => {
                    window.location.href = `/signin${queryUrlPart}`
                }}>Click here to sign in</Button></span>, 0);
            } else {
                return res
            }
        })
        .catch(error => ({error: 'Server Error ' + error}));
};

const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
    SuperFetch[method] = base.bind(null, method);
});

SuperFetch.poll = (urlToCheck, keepCheckingStatuses, totalTimeout = 2000, interval = 100, singleTryCallback = null) => {
    let endTime = Number(new Date()) + (totalTimeout);

    let checkCondition = function (resolve, reject) {
        let result = SuperFetch.get(urlToCheck);
        result.then(function (response) {
            if (singleTryCallback) {
                singleTryCallback(response)
            }
            if (response) {
                if (keepCheckingStatuses.indexOf(response.status) < 0 && !response.hasOwnProperty('error')) {
                    resolve(response);
                } else {
                    if (Number(new Date()) < endTime) {
                        setTimeout(checkCondition, interval, resolve, reject)
                    } else {
                        reject(new Error('timed out for ' + urlToCheck + ' get request: ' + arguments));
                    }
                }
            } else {
                reject(new Error('there is no response from server'))
            }
        });
    };

    return new Promise(checkCondition);
};

export default SuperFetch;
