import Logo from '../image/ProtectedMediaDarkLogo.svg';
import LightLogo from '../image/ProtectedMediaLogo.svg';
import Icon from '../image/ProtectedMediaNewFavicon.svg';

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATETIME_FORMAT_NO_SECONDS = 'YYYY-MM-DD HH:mm';

const MODIFICATION_DATETIME_FORMAT_NO_SECONDS = 'DD/MM/YYYY HH:mm';

export const useApiMock = false;
const port = window.location.hostname.startsWith('localhost') ? ':8000' : '';
export default {
    apiUrl: `${window.location.protocol}//${window.location.hostname}${port}/`
};

const currentDate = new Date();
const WINTER_START = new Date(`${currentDate.getFullYear()}-12-10`);
const WINTER_END = new Date(`${currentDate.getFullYear() + 1}-01-15`);
const isWinter = currentDate >= WINTER_START && currentDate <= WINTER_END;

const isDev = window.location.hostname.indexOf('protected.media') < 0;
const isStaging = window.location.hostname.startsWith('ui-staging.protected.media');


const hideIdentity = window.location.host.startsWith("mondiamedia-ui");
const siteConfig = {
    siteName: hideIdentity ? 'Fraud Analytics' : 'Protected Media',
    siteIcon: Icon,
    siteLogo: hideIdentity ? null : Logo,
    siteLightLogo: hideIdentity ? null : LightLogo,
    footerText: `Protected Media © 2015-${currentDate.getFullYear()}`
};

const themeConfig = {
    topbar: 'protectedmediatheme',
    sidebar: 'protectedmediatheme',
    layout: 'protectedmediatheme',
    theme: 'protectedmediatheme'
};

const googleAnalyticsConfig = {
    trackerId: 'G-B19PZ10XVG',
    uaTrackerId: 'UA-87801102-3',
    defaultPageView: '/dashboard'
};

const gaTrackersNames = ['ga4Tracker', 'uaTracker'];

const fullStoryConfig = {
    orgId: 'MJ6RM'
};

const language = 'english';

const jwtConfig = {
    fetchUrl: 'api/v1/',
    secretKey: 'secretKey',
};

const AlgoliaSearchConfig = {
    appId: '',
    apiKey: ''
};
const Auth0Config = {
    domain: '',
    clientID: '',
    allowedConnections: ['Username-Password-Authentication'],
    rememberLastLogin: true,
    language: 'en',
    closable: true,
    options: {
        auth: {
            autoParseHash: true,
            redirect: true,
            redirectUrl: 'http://localhost:3000/auth0loginCallback'
        },
        languageDictionary: {
            title: 'Isomorphic',
            emailInputPlaceholder: 'demo@gmail.com',
            passwordInputPlaceholder: 'demodemo'
        },
        theme: {
            labeledSubmitButton: true,
            logo: '',
            primaryColor: '#E14615',
            authButtons: {
                connectionName: {
                    displayName: 'Log In',
                    primaryColor: '#b7b7b7',
                    foregroundColor: '#000000'
                }
            }
        }
    }
};
const firebaseConfig = {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: ''
};
const googleConfig = {
    apiKey: '' //
};
const mapboxConfig = {
    tileLayer: '',
    maxZoom: '',
    defaultZoom: '',
    center: []
};
const youtubeSearchApi = '';

export {
    DATETIME_FORMAT,
    DATETIME_FORMAT_NO_SECONDS,
    MODIFICATION_DATETIME_FORMAT_NO_SECONDS,
    isWinter,
    isStaging,
    isDev,
    hideIdentity,
    siteConfig,
    themeConfig,
    language,
    AlgoliaSearchConfig,
    Auth0Config,
    firebaseConfig,
    googleConfig,
    mapboxConfig,
    youtubeSearchApi,
    jwtConfig,
    googleAnalyticsConfig,
    gaTrackersNames,
    fullStoryConfig
};
