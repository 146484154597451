const actions = {
    FETCH_USER: 'FETCH_USER',
    USER_SUCCESS_RESULT: 'USER_SUCCESS_RESULT',
    USER_ERROR_RESULT: 'USER_ERROR_RESULT',
    FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
    FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_NOTIFICATIONS_ERROR: 'FETCH_NOTIFICATIONS_ERROR',
    FETCH_DOCS: 'FETCH_DOCS',
    FETCH_DOCS_SUCCESS: 'FETCH_DOCS_SUCCESS',
    FETCH_DOCS_ERROR: 'FETCH_DOCS_ERROR',
    FETCH_REPORTS: 'FETCH_REPORTS',
    FETCH_REPORTS_SUCCESS: 'FETCH_REPORTS_SUCCESS',
    FETCH_REPORTS_ERROR: 'FETCH_REPORTS_ERROR',
    FETCH_USAGE_NOTIFICATIONS: 'FETCH_USAGE_NOTIFICATIONS',
    FETCH_USAGE_NOTIFICATIONS_SUCCESS: 'FETCH_USAGE_NOTIFICATIONS_SUCCESS',
    FETCH_USAGE_NOTIFICATIONS_ERROR: 'FETCH_USAGE_NOTIFICATIONS_ERROR',
    SWITCH_USAGE_NOTIFICATION: 'SWITCH_USAGE_NOTIFICATIONS',
    SWITCH_USAGE_NOTIFICATION_SUCCESS: 'SWITCH_USAGE_NOTIFICATIONS_SUCCESS',
    SWITCH_USAGE_NOTIFICATION_ERROR: 'SWITCH_USAGE_NOTIFICATIONS_ERROR',
    FETCH_USERS: 'FETCH_USERS',
    FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
    FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',
    USER_UPDATE: 'USER_UPDATE',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_ERROR: 'USER_UPDATE_ERROR',
    FETCH_INTEGRATIONS: 'FETCH_INTEGRATIONS',
    FETCH_INTEGRATIONS_SUCCESS: 'FETCH_INTEGRATIONS_SUCCESS',
    FETCH_INTEGRATIONS_ERROR: 'FETCH_INTEGRATIONS_ERROR',
    UPDATE_INTEGRATION: 'UPDATE_INTEGRATION',
    UPDATE_INTEGRATION_SUCCESS: 'UPDATE_INTEGRATION_SUCCESS',
    UPDATE_INTEGRATION_ERROR: 'UPDATE_INTEGRATION_ERROR',
    REMOVE_INTEGRATION: 'REMOVE_INTEGRATION',
    REMOVE_INTEGRATION_SUCCESS: 'REMOVE_INTEGRATION_SUCCESS',
    REMOVE_INTEGRATION_ERROR: 'REMOVE_INTEGRATION_ERROR',
    CHANGE_INTEGRATION_EDIT_MODE: 'CHANGE_INTEGRATION_EDIT_MODE',
    FETCH_BLOCKING_POLICIES: 'FETCH_BLOCKING_POLICIES',
    FETCH_BLOCKING_POLICIES_SUCCESS: 'FETCH_BLOCKING_POLICIES_SUCCESS',
    FETCH_BLOCKING_POLICIES_ERROR: 'FETCH_BLOCKING_POLICIES_ERROR',
    UPDATE_BLOCKING_POLICY: 'UPDATE_BLOCKING_POLICY',
    UPDATE_BLOCKING_POLICY_SUCCESS: 'UPDATE_BLOCKING_POLICY_SUCCESS',
    UPDATE_BLOCKING_POLICY_ERROR: 'UPDATE_BLOCKING_POLICY_ERROR',
    REMOVE_BLOCKING_POLICY: 'REMOVE_BLOCKING_POLICY',
    REMOVE_BLOCKING_POLICY_SUCCESS: 'REMOVE_BLOCKING_POLICY_SUCCESS',
    REMOVE_BLOCKING_POLICY_ERROR: 'REMOVE_BLOCKING_POLICY_ERROR',
    CHANGE_BLOCKING_POLICY_EDIT_MODE: 'CHANGE_BLOCKING_POLICY_EDIT_MODE',



    userFetch: (history) => ({
        type: actions.FETCH_USER,
        history
    }),
    userFetchSuccess: (result) => ({
        type: actions.USER_SUCCESS_RESULT,
        pids: result.pids,
        isMaster: result.isMaster,
        name: result.name,
        username: result.username,
        updateBlockingPermissions: result.updateBlockingPermissions,
        docsAuthToken: result.docsAuthToken
    }),
    userFetchError: () => ({
        type: actions.USER_ERROR_RESULT
    }),

    notificationsFetch: () => ({
        type: actions.FETCH_NOTIFICATIONS
    }),
    notificationsFetchSuccess: (result) => ({
        type: actions.FETCH_NOTIFICATIONS_SUCCESS,
        notifications: result.notifications
    }),
    notificationsFetchError: () => ({
        type: actions.FETCH_NOTIFICATIONS_ERROR
    }),

    docsFetch: () => ({
        type: actions.FETCH_DOCS
    }),
    docsFetchSuccess: (result) => ({
        type: actions.FETCH_DOCS_SUCCESS,
        docs: result.docs
    }),
    docsFetchError: () => ({
        type: actions.FETCH_DOCS_ERROR
    }),

    reportsFetch: (reportId) => ({
        type: actions.FETCH_REPORTS,
        reportId: reportId
    }),
    reportsFetchSuccess: (result) => ({
        type: actions.FETCH_REPORTS_SUCCESS,
        reports: result.reports
    }),
    reportsFetchError: () => ({
        type: actions.FETCH_REPORTS_ERROR
    }),

    usageNotificationsFetch: () => ({
        type: actions.FETCH_USAGE_NOTIFICATIONS
    }),
    usageNotificationsFetchSuccess: (result) => ({
        type: actions.FETCH_USAGE_NOTIFICATIONS_SUCCESS,
        usageNotifications: result.usageNotifications
    }),
    usageNotificationsFetchError: () => ({
        type: actions.FETCH_USAGE_NOTIFICATIONS_ERROR
    }),

    switchUsageNotification: (payload) => ({
        type: actions.SWITCH_USAGE_NOTIFICATION,
        usageNotification: payload.usageNotification
    }),
    switchUsageNotificationSuccess: () => ({
        type: actions.SWITCH_USAGE_NOTIFICATION_SUCCESS,
    }),
    switchUsageNotificationError: () => ({
        type: actions.SWITCH_USAGE_NOTIFICATION_ERROR
    }),

    usersFetch: (params) => ({
        type: actions.FETCH_USERS,
        payload: params
    }),
    usersFetchSuccess: (result) => ({
        type: actions.FETCH_USERS_SUCCESS,
        users: result.users,
        pagination: result.pagination
    }),
    usersFetchError: () => ({
        type: actions.FETCH_USERS_ERROR
    }),

    userUpdate: (userProperties) => ({
        type: actions.USER_UPDATE,
        userProperties
    }),
    userUpdateSuccess: () => ({
        type: actions.USER_UPDATE_SUCCESS,
    }),
    userUpdateError: () => ({
        type: actions.USER_UPDATE_ERROR
    }),

    integrationsFetch: () => ({
        type: actions.FETCH_INTEGRATIONS
    }),
    integrationsFetchSuccess: (result) => ({
        type: actions.FETCH_INTEGRATIONS_SUCCESS,
        integrationParameters: result.integrationParameters,
        platforms: result.platforms,
        integrations: result.integrations
    }),
    integrationsFetchError: () => ({
        type: actions.FETCH_INTEGRATIONS_ERROR
    }),
    integrationUpdate: (integrationProperties) => ({
        type: actions.UPDATE_INTEGRATION,
        integrationProperties
    }),
    integrationUpdateSuccess: () => ({
        type: actions.UPDATE_INTEGRATION_SUCCESS,
    }),
    integrationUpdateError: () => ({
        type: actions.UPDATE_INTEGRATION_ERROR
    }),
    removeIntegration: (integrationProperties) => ({
        type: actions.REMOVE_INTEGRATION,
        integrationProperties
    }),
    removeIntegrationSuccess: () => ({
        type: actions.REMOVE_INTEGRATION_SUCCESS
    }),
    removeIntegrationError: () => ({
        type: actions.REMOVE_INTEGRATION_ERROR
    }),
    changeIntegrationEditMode: (editMode) => ({
        type: actions.CHANGE_INTEGRATION_EDIT_MODE,
        editMode
    }),

    blockingPoliciesFetch: () => ({
        type: actions.FETCH_BLOCKING_POLICIES
    }),
    blockingPoliciesFetchSuccess: (result) => ({
        type: actions.FETCH_BLOCKING_POLICIES_SUCCESS,
        blockingPolicies: result.blockingPolicies,
        customCategories: result.customCategories,
        urlLists: result.urlLists,
        bundleLists: result.bundleLists
    }),
    blockingPoliciesFetchError: () => ({
        type: actions.FETCH_BLOCKING_POLICIES_ERROR
    }),
    blockingPolicyUpdate: (blockingPolicyProperties, blockingListType) => ({
        type: actions.UPDATE_BLOCKING_POLICY,
        blockingPolicyProperties,
        blockingListType
    }),
    blockingPolicyUpdateSuccess: () => ({
        type: actions.UPDATE_BLOCKING_POLICY_SUCCESS,
    }),
    blockingPolicyUpdateError: () => ({
        type: actions.UPDATE_BLOCKING_POLICY_ERROR
    }),
    removeBlockingPolicy: (blockingPolicyProperties, blockingListType) => ({
        type: actions.REMOVE_BLOCKING_POLICY,
        blockingPolicyProperties,
        blockingListType
    }),
    removeBlockingPolicySuccess: () => ({
        type: actions.REMOVE_BLOCKING_POLICY_SUCCESS
    }),
    removeBlockingPolicyError: () => ({
        type: actions.REMOVE_BLOCKING_POLICY_ERROR
    }),
    changeBlockingPoliciesEditMode: (editMode) => ({
        type: actions.CHANGE_BLOCKING_POLICY_EDIT_MODE,
        editMode
    }),

};
export default actions;
