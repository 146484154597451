import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Layout} from "antd";
import appActions from "../../redux/app/actions";
import TopbarNotification from "../../protectedMediaDashboardApp/containers/topbar/topbarNotification";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import {MenuCloseSVG, MenuOpenSVG} from "../../protectedMediaDashboardApp/components/icons/icons";
import {fireEventToGoogleAnalytics} from "../../helpers/utility";
import defaultPic from "../../image/chewie100x100.png";
import {siteConfig} from "../../settings";
import Logo from "../../components/utility/logo";
import Tooltip from "../../components/uielements/tooltip";
import basicStyle from "../../settings/basicStyle";
import TopbarOpenSupportTicket from "../../protectedMediaDashboardApp/containers/topbar/topbarOpenSupportTicket";

const {Header} = Layout;
const {toggleCollapsed} = appActions;

class Topbar extends Component {

    handleCollapseMenuClick = (isCollapsed) => {
        if (isCollapsed) {
            localStorage.setItem('menuDefault', 'opened');
            fireEventToGoogleAnalytics('Main Menu', 'Open Mode Change', 'To Open By Click')
        } else {
            localStorage.setItem('menuDefault', 'closed');
            fireEventToGoogleAnalytics('Main Menu', 'Open Mode Change', 'To Open By Hover')
        }
        this.props.toggleCollapsed();
    };

    useDefaultLogo = (ev) => {
        ev.target.src = siteConfig.siteIcon
    };

    render() {
        const {customizedTheme, locale} = this.props;
        const collapsed = this.props.collapsed && !this.props.openDrawer;

        const styling = {
            background: customizedTheme.backgroundColor,
            position: "fixed",
            width: "100%",
            height: 70
        };

        return (
            <TopbarWrapper>
                <Header
                    style={styling}
                    className={
                        collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
                    }
                >
                    <div className='onPrintOnly'>
                        <Logo imgStyle={{maxHeight: 20}}/>
                    </div>
                    <div className="isoLeft">
                        <Icon
                            component={collapsed ? MenuOpenSVG : MenuCloseSVG}
                            className={
                                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
                            }
                            style={{color: customizedTheme.textColor}}
                            onClick={() => this.handleCollapseMenuClick(collapsed)}
                        />

                    </div>

                    <ul className="isoRight">
                        {/*<Tooltip title="Smart Agent" placement="left">*/}

                        {/*    <li*/}
                        {/*        onClick={() => this.setState({selectedItem: "smartAgent"})}*/}
                        {/*        className="smartAgent">*/}
                        {/*        <TopbarSmartAgent locale={locale}/>*/}
                        {/*    </li>*/}
                        {/*</Tooltip>*/}

                        <li style={{cursor: 'default'}}>
                            {this.props.isLoading ? <Icon type="loading"/> :
                                <div className="isoImgWrapper">
                                    <img src={this.props.logoUrl} onError={this.useDefaultLogo}
                                         style={{maxHeight: 56, maxWidth: 200}} alt=''/>
                                </div>}
                        </li>

                        <Tooltip title={'Notifications'} overlayStyle={basicStyle.tooltipStyle}>
                            <li
                                onClick={() => this.setState({selectedItem: "notification"})}
                                className="isoNotify"
                            >
                                <TopbarNotification locale={locale}/>
                            </li>
                        </Tooltip>

                        <Tooltip title={'Share your feedback'} overlayStyle={basicStyle.tooltipStyle}>
                            <li>
                                <TopbarOpenSupportTicket/>
                            </li>
                        </Tooltip>

                        <li
                            onClick={() => this.setState({selectedItem: "user"})}
                            className="isoUser"
                        >
                            <TopbarUser locale={locale} userName={this.props.userName}/>
                        </li>

                    </ul>
                </Header>
            </TopbarWrapper>
        );
    }
}

export default connect(
    state => {
        const userPids = Object.values(state.UsersFetch.pids)
        const savedPid = state.ReportsFetch.savedReportProperties && state.ReportsFetch.savedReportProperties.pid
            ? state.ReportsFetch.savedReportProperties.pid.pid
            : ''
        const currentPid = userPids.find(pid => pid.pid === savedPid)

        const logoUrl = state.ReportsFetch.reportProperties.pid.pid
            ? state.ReportsFetch.reportProperties.pid.logo || (state.ReportsFetch.editMode && ((currentPid && currentPid.logo) || defaultPic))
            : '';
        return {
            ...state.App,
            locale: state.LanguageSwitcher.language.locale,
            customizedTheme: state.ThemeSwitcher.topbarTheme,
            userName: state.UsersFetch.name ? state.UsersFetch.name.split(' ').map(name => name[0]).join('') : 'U',
            logoUrl: logoUrl,
            isLoading: state.UsersFetch.loading || state.ReportsFetch.loading,
            reportProperties: state.ReportsFetch.reportProperties
        }
    },
    {toggleCollapsed}
)(Topbar);
