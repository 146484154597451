import actions from './actions';

const initState = {
    pids: {},
    loading: false,
    isMaster: false,
    error: false,
    name: 'Protected Media',
    username: 'user@protected.media',
    updateBlockingPermissions: [],
    notifications: [],
    docs: [],
    reports: [],
    usageNotifications: [],
    loadingUsers: false,
    loadingReports: false,
    users: [],
    loadingIntegrations: false,
    integrationParameters: [],
    platforms: [],
    integrations: [],
    integrationEditMode: false,
    loadingBlockingPolicy: false,
    blockingPolicies: [],
    customCategories: [],
    urlLists: [],
    bundleLists: [],
    blockingPolicyEditMode: false,
    docsAuthToken: '',
    pagination: {
        page: 1,
        pageSize: 10,
        total: 0
    }
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_USER:
            return {
                ...state,
                loading: true
            };
        case actions.USER_SUCCESS_RESULT:
            return {
                ...state,
                loading: false,
                error: false,
                pids: action.pids,
                isMaster: action.isMaster,
                name: action.name,
                username: action.username,
                isInternalUser: action.isMaster || action.username.endsWith('@mediaocean.com') ||
                    action.username.endsWith('@flashtalking.com'),
                updateBlockingPermissions: action.updateBlockingPermissions,
                docsAuthToken: action.docsAuthToken
            };
        case actions.USER_ERROR_RESULT:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actions.FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.notifications,
            };
        case actions.FETCH_DOCS:
            return {
                ...state,
                loading: true
            };
        case actions.FETCH_DOCS_SUCCESS:
            return {
                ...state,
                loading: false,
                docs: action.docs
            };
        case actions.FETCH_DOCS_ERROR:
            return {
                ...state,
                loading: false
            };
        case actions.FETCH_REPORTS:
            return {
                ...state,
                loadingReports: true
            };
        case actions.FETCH_REPORTS_SUCCESS:
            return {
                ...state,
                loadingReports: false,
                reports: action.reports
            };
        case actions.FETCH_REPORTS_ERROR:
            return {
                ...state,
                loadingReports: false
            };
        case actions.FETCH_USAGE_NOTIFICATIONS:
            return {
                ...state,
                loading: true
            };
        case actions.FETCH_USAGE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                usageNotifications: action.usageNotifications
            };
        case actions.FETCH_USAGE_NOTIFICATIONS_ERROR:
            return {
                ...state,
                loading: false
            };
        case actions.SWITCH_USAGE_NOTIFICATION:
            return {
                ...state,
                loading: true
            };
        case actions.SWITCH_USAGE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.SWITCH_USAGE_NOTIFICATION_ERROR:
            return {
                ...state,
                loading: false
            };
        case actions.FETCH_USERS:
            return {
                ...state,
                loadingUsers: true
            };
        case actions.FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.users,
                pagination: {
                    ...state.pagination,
                    page: action.pagination.page,
                    pageSize: action.pagination.pageSize,
                    total: action.pagination.total
                },
                loadingUsers: false
            };
        case actions.FETCH_USERS_ERROR:
            return {
                ...state,
                loadingUsers: false
            };
        case actions.USER_UPDATE:
            return {
                ...state,
                loadingUsers: true
            };
        case actions.USER_UPDATE_SUCCESS:
            return {
                ...state,
                loadingUsers: false,
            };
        case actions.USER_UPDATE_ERROR:
            return {
                ...state,
                loadingUsers: false
            };
        case actions.FETCH_INTEGRATIONS:
            return {
                ...state,
                loadingIntegrations: true
            };
        case actions.FETCH_INTEGRATIONS_SUCCESS:
            return {
                ...state,
                loadingIntegrations: false,
                integrationParameters: action.integrationParameters,
                platforms: action.platforms,
                integrations: action.integrations
            };
        case actions.FETCH_INTEGRATIONS_ERROR:
            return {
                ...state,
                loadingIntegrations: false
            };
        case actions.UPDATE_INTEGRATION:
            return {
                ...state,
                loadingIntegrations: true
            };
        case actions.UPDATE_INTEGRATION_SUCCESS:
            return {
                ...state,
                loadingIntegrations: false,
                integrationEditMode: false
            };
        case actions.UPDATE_INTEGRATION_ERROR:
            return {
                ...state,
                loadingIntegrations: false
            };
        case actions.REMOVE_INTEGRATION:
            return {
                ...state,
                loadingIntegrations: true
            };
        case actions.REMOVE_INTEGRATION_SUCCESS:
            return {
                ...state,
                loadingIntegrations: false
            };
        case actions.REMOVE_INTEGRATION_ERROR:
            return {
                ...state,
                loadingIntegrations: false
            };
        case actions.CHANGE_INTEGRATION_EDIT_MODE:
            return {
                ...state,
                integrationEditMode: action.editMode
            };


        case actions.FETCH_BLOCKING_POLICIES:
            return {
                ...state,
                loadingBlockingPolicy: true,
            };
        case actions.FETCH_BLOCKING_POLICIES_SUCCESS:
            return {
                ...state,
                loadingBlockingPolicy: false,
                blockingPolicies: action.blockingPolicies || [],
                customCategories: action.customCategories || [],
                urlLists: action.urlLists || [],
                bundleLists: action.bundleLists || []
            };
        case actions.FETCH_BLOCKING_POLICIES_ERROR:
            return {
                ...state,
                loadingBlockingPolicy: false
            };
        case actions.UPDATE_BLOCKING_POLICY:
            return {
                ...state,
                loadingBlockingPolicy: true
            };
        case actions.UPDATE_BLOCKING_POLICY_SUCCESS:
            return {
                ...state,
                loadingBlockingPolicy: false,
                blockingPolicyEditMode: false
            };
        case actions.UPDATE_BLOCKING_POLICY_ERROR:
            return {
                ...state,
                loadingBlockingPolicy: false
            };
        case actions.REMOVE_BLOCKING_POLICY:
            return {
                ...state,
                loadingBlockingPolicy: true
            };
        case actions.REMOVE_BLOCKING_POLICY_SUCCESS:
            return {
                ...state,
                loadingBlockingPolicy: false
            };
        case actions.REMOVE_BLOCKING_POLICY_ERROR:
            return {
                ...state,
                loadingBlockingPolicy: false
            }
        case actions.CHANGE_BLOCKING_POLICY_EDIT_MODE:
            return {
                ...state,
                blockingPolicyEditMode: action.editMode
            }


        default:
            return state;
    }
}
